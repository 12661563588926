import React, { useEffect, useState } from "react";
import "./CustomGallery.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../common/url/urls";

const CustomGallery: React.FC = (props) => {
  const query = useLocation();
  const [fullView, viewImage] = useState({ view: false, src: "", index: -1 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [galleryImages, setGalleryImages] = useState<any>([]);

  const setImageSizeHandler = ({ target }: any) => {
    setImageSize({
      width: target.naturalWidth,
      height: target.naturalHeight,
    });
  };

  const goLeftHandler = () => {
    if (fullView.index < 1) {
      viewImage({
        view: true,
        src: galleryImages[galleryImages.length - 1].imageURL,
        index: galleryImages.length - 1,
      });
    } else {
      viewImage({
        view: true,
        src: galleryImages[fullView.index - 1].imageURL,
        index: fullView.index - 1,
      });
    }
  };

  const goRightHandler = () => {
    if (fullView.index > galleryImages.length - 1) {
      viewImage({ view: true, src: galleryImages[0].imageURL, index: 0 });
    } else {
      viewImage({
        view: true,
        src: galleryImages[fullView.index + 1].imageURL,
        index: fullView.index + 1,
      });
    }
  };

  const getGalleryImages = async (id: number) => {
    try {
      const response = await axios.get(BASE_URL + "/gallery/" + id);
      setGalleryImages(response.data.images);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryImages(
      parseInt(query.pathname.split("/")[query.pathname.split("/").length - 1])
    );
  }, []);

  return (
    <div className={"gallery_container"}>
      {galleryImages.map((image: any, index: number) => (
        <div key={"image_single_" + index} className={"image_wrapper"}>
          <img
            src={image.imageURL}
            onClick={() =>
              viewImage({ view: true, src: image.imageURL, index: index })
            }
          />
        </div>
      ))}
      {fullView.view && (
        <div className={"custom_lightbox"}>
          <img
            className={"close_icon"}
            src={require("../../assets/pin-icon.png")}
            onClick={() => viewImage({ view: false, src: "", index: -1 })}
          />
          <img
            className={"left_arrow"}
            src={require("../../assets/arrow-left.png")}
            onClick={goLeftHandler}
          />
          <img
            className={"full_size_image"}
            onLoad={setImageSizeHandler}
            id={"full_size_image"}
            src={fullView.src}
            style={
              imageSize.height > imageSize.width
                ? { width: "auto", height: "90vh" }
                : {}
            }
          />
          <img
            className={"right_arrow"}
            src={require("../../assets/arrow-right.png")}
            onClick={goRightHandler}
          />
        </div>
      )}
    </div>
  );
};

export default CustomGallery;
